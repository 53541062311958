import { AppThunk, RootState } from './../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
// import * as cookie from 'react-cookies';
import API, { ThenArg } from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  stopFetching,
} from '../../utilities/redux';
import { loadCartCookie, removeCartCookie, saveCartCookie } from './helpers';
import { langSelector, currencySelector, userSelector } from '../App/selectors';
import { prop, arrayToObject, round } from '../../utilities';
import { getProductDefaultInfo } from 'eshop-defaults/lib/utilities/selectors';
import { createNewCart } from './utilities';
import { ecoTrackCart } from '@bart.sk-ecommerce/react-online-marketing';

interface CartState {
  data: ThenArg<typeof API.getCart> | null;
  error: ErrorTypeAPI;
  isFetching: boolean;
  showProblems: boolean;
  deliveryPaymentInfo: {
    data: ThenArg<typeof API.getDeliveryPaymentInfo> | null;
    byId: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>> | null;
    ids: string[];
    isFetching: boolean;
    error: ErrorTypeAPI;
  };
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalFetching: boolean;
  wasAddedToCartModalGoodId: any;
  wasAddedToCartModalBoughtToo:
    | ThenArg<typeof API.loadProductConnections>
    | ThenArg<typeof API.searchProducts>
    | null;
  freeDelivery: ThenArg<typeof API.getFreeDeliveryInfo> | null;
}

const initialState: CartState = {
  data: null,
  isFetching: false,
  error: null,
  showProblems: false,
  deliveryPaymentInfo: {
    data: null,
    isFetching: false,
    error: null,
    byId: null,
    ids: [],
  },
  addToCartModal: false,
  addToCartModalProduct: null,
  addToCartModalIsRequest: false,
  wasAddedToCartModal: false,
  wasAddedToCartModalProduct: null,
  wasAddedToCartModalCount: 0,
  wasAddedToCartModalFetching: false,
  wasAddedToCartModalBoughtToo: null,
  wasAddedToCartModalGoodId: null,
  freeDelivery: null,
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setShowProblems(state, action: PayloadAction<{ show: boolean }>) {
      state.showProblems = action.payload.show;
    },

    setInitialCart(state) {
      state.data = null;
      state.error = null;
      stopFetching(state, '');
    },
    startFetchingCart(state) {
      startFetching(state, '');
    },
    fetchCartSuccess(
      state,
      action: PayloadAction<{
        cart: ThenArg<typeof API.getCart>;
      }>,
    ) {
      state.data = action.payload.cart;
      state.error = null;
      stopFetching(state, '');
    },
    fetchCartError(state, action: PayloadAction<ErrorTypeAPI>) {
      // check if it is not 404
      if (action.payload?.status !== 404) {
        state.error = action.payload;
      }
      stopFetching(state, '');
    },

    startFetchingDelPayInfo(state) {
      startFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoSuccess(
      state,
      action: PayloadAction<{
        ids: string[];
        deliveriesById: Record<
          string,
          ThenArg<typeof API.getDeliveryPaymentInfo>
        >;
      }>,
    ) {
      state.deliveryPaymentInfo.ids = action.payload.ids;
      state.deliveryPaymentInfo.byId = action.payload.deliveriesById;
      stopFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, 'deliveryPaymentInfo');
    },

    setAddToCartModal(
      state,
      action: PayloadAction<{ product?: any; isRequest?: boolean }>,
    ) {
      state.addToCartModal = !state.addToCartModal;
      state.addToCartModalProduct = action.payload.product || null;
      state.addToCartModalIsRequest = action.payload.isRequest || false;
    },

    setWasAddedToCartModal(
      state,
      action: PayloadAction<{ product?: any; count?: number; goodId?: number }>,
    ) {
      state.wasAddedToCartModal = !state.wasAddedToCartModal;
      state.wasAddedToCartModalProduct = action.payload.product || null;
      state.wasAddedToCartModalCount = action.payload.count || 0;
      state.wasAddedToCartModalGoodId = action.payload.goodId || 0;
    },

    fetchProductInCartRecommended(state) {
      state.wasAddedToCartModalFetching = true;
    },
    setProductInCartRecommendedSuccess(
      state,
      action: PayloadAction<{
        connections:
          | ThenArg<typeof API.loadProductConnections>
          | ThenArg<typeof API.searchProducts>;
      }>,
    ) {
      state.wasAddedToCartModalBoughtToo = action.payload.connections;
      state['wasAddedToCartModalFetching'] = false;
    },
    setProductInCartRecommendedError(
      state,
      action: PayloadAction<ErrorTypeAPI>,
    ) {
      state.error = action.payload;
      state.wasAddedToCartModalFetching = false;
    },

    setFreeDelInfo(
      state,
      action: PayloadAction<{
        freeDelInfo: ThenArg<typeof API.getFreeDeliveryInfo>;
      }>,
    ) {
      state.freeDelivery = action.payload.freeDelInfo || null;
    },
  },
});

export const {
  startFetchingCart,
  fetchCartSuccess,
  fetchCartError,
  startFetchingDelPayInfo,
  fetchDelPayInfoSuccess,
  fetchDelPayInfoError,
  setAddToCartModal,
  setWasAddedToCartModal,
  fetchProductInCartRecommended,
  setProductInCartRecommendedSuccess,
  setProductInCartRecommendedError,
  setInitialCart,
  setFreeDelInfo,
  setShowProblems,
} = cart.actions;
export default cart.reducer;

const cartDomainSelector = (state: RootState) => state.cart;

export const cartDataSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data', null),
);

export const cartIdSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data.id', null),
);

export const cartIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'isFetching', null),
);

export const cartErrorSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'error', null),
);

export const cartDelPayInfoIdsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.ids', null),
);

export const cartDelPayInfoByIdSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.byId', null),
);

export const cartDelPayInfoIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.isFetching', null),
);

export const addToCartModalVisibleSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModal', false),
);

export const cartShowProblemsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'showProblems', false),
);

export const addToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalProduct', null),
);

export const addToCartModalIsRequestSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalIsRequest', false),
);

export const wasAddedToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalProduct', null),
);

export const wasAddedToCartModalSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModal', false),
);

export const wasAddedToCartModalCountSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalCount', 0),
);

export const wasAddedToCartModalIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalFetching', false),
);

export const wasAddedToCartModalBoughtTooSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalBoughtToo', null),
);

export const wasAddedToCartModalGoodIdSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalGoodId', null),
);

export const freeDelRemainingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'freeDelivery.remaining_price', null),
);

export const setAddToCartModalVisibility = (
  isRequest?: boolean,
  product?: any,
): AppThunk => dispatch => {
  dispatch(setAddToCartModal({ product, isRequest }));
};

export const setWasAddedToCartModalVisibility = (
  product?: any,
  count?: number,
  goodId?: number,
): AppThunk => dispatch => {
  dispatch(setWasAddedToCartModal({ product, count, goodId }));
};

export const fetchCart = (
  refresh: boolean = false,
  cartCookie?: string,
  newlyGeneratedId?: string,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const userCartId = prop(state, 'auth.user.cartId');
  const cookieCartId = loadCartCookie(cartCookie);
  const freeDel = prop(state, 'cart.freeDelivery');

  if (
    refresh ||
    (userCartId && userCartId !== cookieCartId) ||
    !cartDataSelector(state)
  ) {
    let cartId: string | undefined =
      newlyGeneratedId || userCartId || cookieCartId;

    if (!cartId) {
      return;
    }

    if (cartId) {
      if (!freeDel) {
        const freeDelInfo = await API.getFreeDeliveryInfo(cartId, {});
        dispatch(setFreeDelInfo({ freeDelInfo }));
      }

      saveCartCookie(cartId);
      try {
        dispatch(startFetchingCart());
        const cart = await API.getCart(cartId, { vatGroups: 0 });
        dispatch(fetchCartSuccess({ cart }));
      } catch (err) {
        if (err.details.status === 404 && !newlyGeneratedId) {
          const user = userSelector(state);
          if (user) {
            const userInfo = await API.tokeninfo({ accessToken: user.token });
            if (prop(userInfo, 'cartId')) {
              dispatch(fetchCart(refresh, '', prop(userInfo, 'cartId')));
              return;
            }
          }
          const id = await createNewCart(state, API);
          removeCartCookie();
          saveCartCookie(id);
          dispatch(fetchCart(refresh));
        } else if (
          err.details.status === 500 &&
          err.details.description &&
          err.details.description.includes(
            "Cannot read property 'good' of undefined",
          )
        ) {
          const id = await createNewCart(state, API);
          removeCartCookie();
          saveCartCookie(id);
          dispatch(fetchCart(refresh));
        } else {
          if (
            !prop(err, 'details.description').includes(
              'Nie je možné pridať takéto množstvo',
            )
          ) {
            dispatch(fetchCartError(err));
          }

          if (window) {
            window.alert(
              prop(
                err,
                'details.description',
                'Nastala chyba pri získavaní košíka',
              ),
            );
          }
        }
      }
    }
  }
};

export const addItemToCartFromModal = (count: number = 1): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const product = addToCartModalProductSelector(state);
  const isRequest = addToCartModalIsRequestSelector(state);
  const { productId, goodId } = getProductDefaultInfo(product);

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        isDemand: isRequest ? 1 : 0,
        currencyId: 'EUR',
      },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
    dispatch(setAddToCartModalVisibility());
    dispatch(setWasAddedToCartModalVisibility(product, count, goodId));

    const freeDelInfo = await API.getFreeDeliveryInfo(cartId, {});
    dispatch(setFreeDelInfo({ freeDelInfo }));
  } catch (err) {
    if (err.details.status === 404) {
      const id = await createNewCart(state, API);
      removeCartCookie();
      saveCartCookie(id);
      dispatch(addItemToCartFromModal(count));
    } else {
      if (
        !prop(err, 'details.description').includes(
          'Nie je možné pridať takéto množstvo',
        )
      ) {
        dispatch(fetchCartError(err));
      }

      if (window) {
        window.alert(
          prop(
            err,
            'details.description',
            'Nastala chyba pri pridávaní do košíka',
          ),
        );
      }
    }
  }
};

export const addItemToCart = (
  product: any = null,
  goodId: number,
  productId: number,
  count: number = 1,
  isDemand: boolean = false,
  openModalAfter: boolean = true,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  const imageUrl = process.env.REACT_APP_API_BASE_URL + '/image?query=';
  const productUrl = process.env.REACT_APP_BASE_URL;

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        isDemand: isDemand ? 1 : 0,
        currencyId: 'EUR',
        vatGroups: 0,
      },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));

    ecoTrackCart(cart, imageUrl, productUrl);

    if (openModalAfter) {
      dispatch(setAddToCartModalVisibility());
      dispatch(setWasAddedToCartModalVisibility(product, count, goodId));
    }

    const freeDelInfo = await API.getFreeDeliveryInfo(cartId, {});
    dispatch(setFreeDelInfo({ freeDelInfo }));
  } catch (err) {
    if (err.details.status === 404) {
      const id = await createNewCart(state, API);
      removeCartCookie();
      saveCartCookie(id);
      dispatch(
        addItemToCart(
          product,
          goodId,
          productId,
          count,
          isDemand,
          openModalAfter,
        ),
      );
      ecoTrackCart(cart, imageUrl, productUrl);
    } else {
      if (
        !prop(err, 'details.description').includes(
          'Nie je možné pridať takéto množstvo',
        )
      ) {
        dispatch(fetchCartError(err));
      }

      if (window) {
        window.alert(
          prop(
            err,
            'details.description',
            'Nastala chyba pri pridávaní do košíka',
          ),
        );
      }
    }
  }
};
export const addBulkItemsToCart = (products: any[] = []): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');
  const imageUrl = process.env.REACT_APP_API_BASE_URL + '/image?query=';
  const productUrl = process.env.REACT_APP_BASE_URL;
  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const items: {
      product_id: string;
      good_id: string;
      count: number;
      is_demand: boolean;
      vat_groups: boolean;
    }[] = [];

    for (const product of products) {
      const { product_id, good_id, quantity } = product;

      items.push({
        good_id: good_id.toString(),
        product_id: product_id.toString(),
        count: +quantity,
        is_demand: false,
        vat_groups: false,
      });
    }
    await API.addBulkCartItems(cartId, { currencyId: 'EUR' }, items, {
      xAcceptLanguage: langSelector(state),
    });

    const cart = await API.getCart(cartId, { vatGroups: 0 });

    ecoTrackCart(cart, imageUrl, productUrl);
    const freeDelInfo = await API.getFreeDeliveryInfo(cartId, {});
    dispatch(setFreeDelInfo({ freeDelInfo }));
    dispatch(setAddToCartModalVisibility());
    dispatch(setWasAddedToCartModalVisibility({}, 1, 1));
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {}
};

export const addFullOrderToCart = (publicId: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');
  const imageUrl = process.env.REACT_APP_API_BASE_URL + '/image?query=';
  const productUrl = process.env.REACT_APP_BASE_URL;
  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }
  try {
    dispatch(startFetchingCart());

    const cart = await API.addOrderItemsToCart(
      cartId,
      { publicId },
      {
        xAcceptLanguage: langSelector(state),
      },
    );

    ecoTrackCart(cart, imageUrl, productUrl);
    const freeDelInfo = await API.getFreeDeliveryInfo(cartId, {});
    dispatch(setFreeDelInfo({ freeDelInfo }));
    dispatch(setAddToCartModalVisibility());
    dispatch(setWasAddedToCartModalVisibility({}, 1, 1));

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {}
};

export const removeItemFromCart = (
  goodId: number,
  productId: number,
  isRequest?: boolean,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { id } = cartDataSelector(state);
  const imageUrl = process.env.REACT_APP_API_BASE_URL + '/image?query=';
  const productUrl = process.env.REACT_APP_BASE_URL;
  try {
    dispatch(startFetchingCart());
    const cart = await API.deleteCartItem(id, productId, goodId, {
      isDemand: isRequest ? 1 : 0,
      vatGroups: 0,
    });
    dispatch(fetchCartSuccess({ cart }));
    ecoTrackCart(cart, imageUrl, productUrl);
    const freeDelInfo = await API.getFreeDeliveryInfo(id, {});
    dispatch(setFreeDelInfo({ freeDelInfo }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeItemNote = (
  goodId: number,
  note: string,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.addItemNote(
      id,
      goodId,
      { vatGroups: 0 },
      { note },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeCartNote = (note: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.addNote(
      id,
      { vatGroups: 0 },
      { note },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const updateCart = (
  data: any,
  vatGroups: boolean = false,
  setAfterUpdate: boolean = true,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const cartData = cartDataSelector(state);

  try {
    if (setAfterUpdate) {
      dispatch(startFetchingCart());
    }
    const cart = await API.updateCart(
      cartData.id,
      { vatGroups: vatGroups ? 1 : 0 },
      data,
    );
    if (setAfterUpdate) {
      dispatch(fetchCartSuccess({ cart }));
    } else {
      dispatch(
        fetchCartSuccess({ cart: { ...cartData, problems: cart.problems } }),
      );
    }
  } catch (err) {
    if (setAfterUpdate) {
      dispatch(fetchCartError(err));
    }
  }
};

export const fetchDeliveryPaymentInfo = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingDelPayInfo());
    const info = await API.getDeliveryPaymentInfo(
      id,
      {},
      { xAcceptLanguage: langSelector(state) },
    );
    const deliveriesById = arrayToObject(
      prop(info, 'delivery', []),
      'delivery_id',
    );
    const ids = prop(info, 'delivery', []).map(d => d.delivery_id);
    dispatch(fetchDelPayInfoSuccess({ ids, deliveriesById }));
  } catch (err) {
    dispatch(fetchDelPayInfoError(err));
  }
};

export const loadProductInCartRecommended = (id: number): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(fetchProductInCartRecommended());

    const lang = langSelector(getState());
    const connections = await API.loadProductConnections(
      id,
      {
        connectionType: 'CROSSSELL',
        limit: 4,
      },
      { xAcceptLanguage: lang },
    );
    dispatch(setProductInCartRecommendedSuccess({ connections }));
  } catch (e) {
    dispatch(setProductInCartRecommendedError(e));
  }
};

export const loadProductInCartByCategory = (id: string): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(fetchProductInCartRecommended());
    const lang: string = langSelector(getState());
    const currency = currencySelector(getState());
    const connections = await API.searchProducts(
      {
        categoryId: id,
        limit: 12,
        availabilityId: 1,
        sort: 'random',
        sortDir: 'asc',
      },
      { xAcceptLanguage: lang, xCurrency: currency },
    );
    dispatch(setProductInCartRecommendedSuccess({ connections }));
  } catch (e) {
    dispatch(setProductInCartRecommendedError(e));
  }
};

export const showProblemsInCart = (
  show: boolean,
): AppThunk => async dispatch => {
  dispatch(setShowProblems({ show }));
};

export const resetCart = (): AppThunk => async (dispatch, getState, API) => {
  const state = getState();

  dispatch(setInitialCart());
  await createNewCart(state, API);
};

export const addGiftCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    let cart = await API.addCard(
      id,
      {},
      {
        card_type: 'GIFTCARD',
        card_number: cardNumber,
      },
    );
    cart = await API.getCart(id, { vatGroups: 1 });

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const removeGiftCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    const cart = await API.removeCard(id, 'giftcard', cardNumber);

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const handlePurchase = (order: any) => {
  return async (dispatch, getState, API) => {
    if (order && order.analytics_sended === 0) {
      const items: any[] = [];
      let sumOfItemsPriceWithoutVat = 0;
      let sumOfItemsPriceWithVat = 0;

      for (const item of order.items) {
        if (!item.product_id) {
        } else {
          sumOfItemsPriceWithoutVat += item.totalprice_without_vat;
          sumOfItemsPriceWithVat += item.totalprice;
          items.push({
            id: item.product_id,
            name: item.product_name,
            brand: '',
            category: '',
            quantity: parseInt(item.quantity, 10),
            price: round(item.unitprice, 2).toString(),
            variant: '',
          });
        }
      }
      // if (window && window.gtag) {
      //   console.log('pushing');
      //   window.gtag({ ecommerce: null });
      //   window.gtag({
      //     ecommerce: {
      //       purchase: {
      //         actionField: {
      //           id: order.order_id,
      //           affiliation: 'Sanitaske.sk Eshop',
      //           revenue: round(order.order_tprice, 2).toString(),
      //           tax: round(
      //             sumOfItemsPriceWithVat - sumOfItemsPriceWithoutVat,
      //             2,
      //           ),
      //           shipping: round(
      //             +order.payment.delivery_price + +order.payment.payment_price,
      //             2,
      //           ),
      //         },
      //         products: items,
      //       },
      //     },
      //   });
      // }
      // console.log({ w: (window as any).dataLayer });
      console.log('purchase sent');
      if (window && window.gtag) {
        window.gtag('event', 'purchase', {
          currency: 'EUR',
          id: order.order_id,
          transaction_id: order.order_id,
          affiliation: 'Sanitaske.sk Eshop',
          revenue: round(order.order_tprice, 2).toString(),
          value: round(order.order_tprice, 2).toString(),
          tax: round(sumOfItemsPriceWithVat - sumOfItemsPriceWithoutVat, 2),
          shipping: round(
            +order.payment.delivery_price + +order.payment.payment_price,
            2,
          ),
          items,
        });

        window.gtag('event', 'conversion', {
          send_to: 'AW-318286675/0gDzCJesvJoYENPW4pcB',
          value: round(order.order_tprice, 2).toString(),
          currency: 'EUR',
          transaction_id: order.order_id,
        });
      }

      try {
        await API.updateOrderAnalytics(order.public_id);
      } catch (e) {
        console.error('Update order analytics failed!');
      }
    }
  };
};
